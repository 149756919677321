import React, { useEffect, useState } from "react";
import "./Content.css"; // Import the CSS file
import contentArray from "./ContentArray";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("Who We Are");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTabClick = (title) => {
    if (isMobile) {
      setActiveTab(activeTab === title ? null : title);
    } else {
      setActiveTab(title);
    }
  };

  return (
    <div className="about-us-container">
      {isMobile ? (
        // Mobile Accordion Layout
        <div className="accordion-container">
          {contentArray.map((tab) => (
            <div key={tab.title} className="accordion-item">
              <button
                onClick={() => handleTabClick(tab.title)}
                className={`btn btn-primary w-100 d-flex align-items-center justify-content-between ${activeTab === tab.title ? "active" : ""}`}
              >
                <span>{tab.title}</span>
                <span className="toggle-icon">{activeTab === tab.title ? "−" : "+"}</span>
              </button>
              <div className={`accordion-content ${activeTab === tab.title ? "active" : ""}`}>
                <p>{tab.description}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        // Desktop Tab Layout
        <>
          <div className="tab-buttons">
            {contentArray.map((tab) => (
              <button
                key={tab.title}
                onClick={() => handleTabClick(tab.title)}
                className={`tab-button ${activeTab === tab.title ? "active-tab" : ""}`}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div>
            <p className="tab-content">
              {contentArray.find((tab) => tab.title === activeTab)?.description}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default AboutUs;
