const contentArray = [
    {
      title: "Who We Are",
      description: `
        Our company was founded by a team of seasoned experts in software development, artificial intelligence, and analytics, who shared a common vision of harnessing technology to solve real-world problems. Focusing on delivering innovative and impactful solutions, we quickly expanded our expertise across various industries, including government, retail, and other key sectors. From humble beginnings, we have become a trusted partner for organizations seeking cutting-edge solutions to drive growth, efficiency, and transformation.
      `
    },
    {
      title: "History",
      description: `
        Data Prime has a rich history of innovation in geospatial technology. Our journey began with the development of a proprietary geospatial processing engine. We achieved a milestone by launching Argus-X, a platform designed to provide comprehensive location intelligence. Over time, we have expanded our expertise across diverse sectors such as retail, education, and security. With a focus on scalability, security, and compliance, we have built solutions that meet the needs of organizations of all sizes. Our commitment to excellence continues to drive us forward as we set new standards in location intelligence.
      `
    },
    {
      title: "Mission",
      description: `
        Our mission is to revolutionize the way data is utilized by transforming it into strategic assets that foster innovation and operational excellence. We aim to empower organizations with tools that make location intelligence accessible and actionable. By leveraging cutting-edge technologies, we help our clients uncover insights that drive meaningful change. Our focus is on delivering solutions that are both reliable and impactful across various industries. We prioritize creating value through data-driven decision-making processes. Ultimately, we strive to enhance the efficiency, productivity, and competitiveness of every organization we serve.
      `
    }
  ];

  export default contentArray;