import React from 'react'
import './ContactForm.css'
import { Button } from '../General/General'

const ContactForm = () => {
  return (
    <>
    <div className="contactForm-container d-flex flex-column flex-md-row align-items-center justify-content-center">
      <div className='text-center text-md-start w_50 d-flex flex-column gap-5'> 
        <div >
            <h1 style={{ fontSize: '80px' }} className='font-grotesque m-0 p-0'>Let's Talk.</h1>
            <h4 className='fw-normal font-grotesque'>Got a project on your mind? Let's discuss about the details.</h4>
        </div>
        <div className='tel_container'>
            <h4 className='font-grotesque'>Call Us</h4>
            <h4>(857) 693-8559</h4>
        </div>
      </div>
      <div className='w_50 p-5 d-flex flex-column gap-3 border rounded'>
            <input type="text" className='form-control' placeholder='Name...' />
            <input type="email" className='form-control' placeholder='Email...' />
            <textarea name="" id="" cols="30" className='form-control' placeholder='Message...' rows="10"></textarea>
            <div className='d-flex justify-content-center justify-content-md-start'>
              <Button>Submit</Button>
            </div>
      </div>
    </div> 
    </>
  )
}

export default ContactForm