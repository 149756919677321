import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';
import { FaBars, FaTimes } from 'react-icons/fa'; 
import Logo from '../../../assets/img/logoNoBg.png'
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  return (
    <nav className="nav">
      <div className="logo_div">
        <Link to='/' style={{cursor: 'pointer'}}>
          <img src={Logo} alt="" className='w-100' style={{filter: 'drop-shadow(2px 4px 6px black)'}}/>
        </Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {menuOpen ? <FaTimes className="hamburger-icon" /> : <FaBars className="hamburger-icon" />}
      </div>
      <menu className={`menu m-0 ${menuOpen ? 'open' : ''}`}>
        <ul className='d-flex align-items-center gap-5'>
          {NavLinks.map((item, index) => (
            <li 
              key={index} 
              className={`menu-items ${item.submenu ? 'has-dropdown' : ''}`}
              onMouseEnter={() => item.submenu && setDropdownOpen(index)}
              onMouseLeave={() => item.submenu && setDropdownOpen(null)}
            >
              <Link 
                className="text-uppercase" 
                to={item.link} 
                onClick={() => {
                  setMenuOpen(false);
                  document.body.classList.remove('no-scroll');
                }}
              >
                {item.menu}
              </Link>
              {/* {item.menu === 'About Us' && ( */}
              {item.submenu && dropdownOpen === index && (
                <ul className="dropdown m-0 p-0">
                  {item.submenu.map((subitem, subindex) => (
                    <Link 
                      className="text-uppercase" 
                      to={subitem.link} 
                      onClick={() => {
                        setMenuOpen(false);
                        document.body.classList.remove('no-scroll');
                      }}
                    >
                      <li key={subindex} className="dropdown-item p-3">
                          {subitem.menu}
                      </li>
                    </Link>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </menu>
    </nav>
  );
};

export default Navbar;
