import React, { useEffect, useRef, useState } from 'react'
import './Statistics.css'
import StatImg from '../../../assets/img/StatImg.jpg'

const Statistics = () => {
    const [visible, setVisible] = useState(false);
    const sectionRef = useRef(null);
  
    // Intersection observer to detect when the section is visible
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisible(true);
          }
        },
        { threshold: 0.5 }
      );
  
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
  
      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }, []);

    const progressValues = [
      { "title": "Data-Driven Decision Making", "value": 95 },
      { "title": "Operational Efficiency Improvement", "value": 90 },
      { "title": "Cost Reduction via Optimization", "value": 85 }
    ];

  return (
    <div className='px-5 my-5'>
        <div className="row">
            <div className="col-md-5">
                <div style={{backgroundImage: `url(${StatImg})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '100%', backgroundRepeat: 'no-repeat'}}></div>
            </div>
            <div className="col-md-7 pro__align text-start ">
                <h1 className='pro__title pb-2'>Our Statistics</h1>
                <p className='pro__desc'>
                  At Argus-X, we empower industries with cutting-edge location intelligence solutions. 
                  By leveraging real-time geospatial data, AI, and predictive analytics, we help organizations enhance operational efficiency, 
                  make informed decisions, and optimize resource allocation to achieve measurable results.
                </p>
                <div className="progress-container" ref={sectionRef}>
                    {progressValues.map((prog, index) => (
                        <div key={index} className='py-3'>
                            <div className='d-flex justify-content-between'>
                                <p style={{fontSize: '14px'}}>{prog.title}</p>
                                <p style={{fontSize: '14px'}}>{prog.value} %</p>
                            </div>
                            <div className="progress">
                                <div
                                    className="bar"
                                    style={{ "--progress": visible ? `${prog.value}%` : "0%" }}
                                >
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Statistics