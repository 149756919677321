import React from "react";
import "./Industry.css";
import {
  FaUniversity,
  FaShoppingCart,
  FaBuilding,
  FaBriefcaseMedical,
  FaGraduationCap,
  FaBusinessTime,
  FaSignal,
  FaShieldAlt,
} from "react-icons/fa";

const industries = [
  {
    icon: <FaUniversity />,
    title: "Banking",
    description: "Transforming banking with real-time data intelligence and risk management.",
    points: [
      "Improve risk management and fraud detection using AI-driven insights.",
      "Optimize branch locations based on customer behavior.",
      "Enhance customer segmentation with location-based insights.",
      "Automate compliance reporting with smart solutions.",
    ],
  },
  {
    icon: <FaShoppingCart />,
    title: "Retail",
    description: "Revolutionizing retail operations with location intelligence.",
    points: [
      "Analyze foot traffic for store placement strategies.",
      "Enhance marketing with customer behavior analytics.",
      "Optimize supply chain logistics with real-time tracking.",
      "Reduce costs through data-driven inventory management.",
    ],
  },
  {
    icon: <FaBuilding />,
    title: "Government",
    description: "Empowering governance with geospatial intelligence.",
    points: [
      "Enhance urban planning with data-driven insights.",
      "Improve public safety with real-time risk mapping.",
      "Optimize resource allocation for public services.",
      "Predict disasters and environmental changes efficiently.",
    ],
  },
  {
    icon: <FaBriefcaseMedical />,
    title: "Healthcare",
    description: "Enhancing efficiency with data-driven medical solutions.",
    points: [
      "Optimize hospital placements based on demand analytics.",
      "Improve patient outreach using geospatial data.",
      "Ensure timely medical supply availability.",
      "Advance disease control with predictive insights.",
    ],
  },
  {
    icon: <FaGraduationCap />,
    title: "Education",
    description: "Transforming education strategies with data analytics.",
    points: [
      "Improve campus planning with geospatial analytics.",
      "Optimize student transportation and safety.",
      "Enhance academic research with real-time insights.",
      "Support data-driven policy-making in education.",
    ],
  },
  {
    icon: <FaBusinessTime />,
    title: "Business",
    description: "Driving growth with AI-powered intelligence.",
    points: [
      "Identify new market opportunities with data insights.",
      "Enhance customer targeting using location analytics.",
      "Optimize workforce and resource management.",
      "Leverage predictive analytics for financial forecasting.",
    ],
  },
  {
    icon: <FaSignal />,
    title: "Telecommunications",
    description: "Optimizing network expansion with smart insights.",
    points: [
      "Improve network coverage based on usage patterns.",
      "Deploy 5G efficiently with geospatial intelligence.",
      "Enhance customer acquisition through data insights.",
      "Strengthen infrastructure expansion with AI analytics.",
    ],
  },
  {
    icon: <FaShieldAlt />,
    title: "Insurance",
    description: "Revolutionizing risk assessment with AI & geospatial data.",
    points: [
      "Enhance underwriting with predictive risk models.",
      "Detect fraud faster with AI-driven anomaly detection.",
      "Improve customer segmentation for better targeting.",
      "Optimize policyholder coverage with predictive analytics.",
    ],
  },
];

const Industry = () => {
  return (
    <section className="who-we-serve">
      <div className="industries-grid">
        {industries.map((industry, index) => (
          <div className="industry-card" key={index}>
            <div className="icon">{industry.icon}</div>
            <h3>{industry.title}</h3>
            <p>{industry.description}</p>
            <ul className="industry-points">
              {industry.points.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Industry;
