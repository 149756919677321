import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Home/Navbar/Navbar';
import NotFound from './Components/General/NotFound';
import About from './Pages/About';
import Footer from './Components/Footer/Footer';
import Contact from './Pages/Contact';
import Argus from './Pages/Argus';
import WhatWeDo from './Pages/WhatWeDo';
import WhoWeServe from './Pages/WhoWeServe';

function App() {
  const location = useLocation();
  
  const pagesWithNavbarFooter = ['/', '/About', '/Contact', '/What-We-Do', '/Who-We-Serve'];

  const showNavbarFooter = pagesWithNavbarFooter.includes(location.pathname);

  const queryParams = new URLSearchParams(location.search).get('section');

  

  return (
    <div className="App">
      {showNavbarFooter && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/What-We-Do" element={<WhatWeDo section={queryParams}/>} />
        <Route path="/Who-We-Serve" element={<WhoWeServe />} />
        <Route path="/Argus-x" element={<Argus />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {showNavbarFooter && <Footer />}
    </div>
  );
}

export default App;
