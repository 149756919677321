import React from 'react'
import './Security.css'
import ServiceBg from '../../../assets/img/section_bg.jpg'
import { Button, CardFlow } from '../../General/General';
import { Shield, Shield1, Shield2, Shield3} from '../../icons/Icons';

const Security = () => {
  return (
    <div className='security_section position-relative' style={{backgroundImage: `url(${ServiceBg})`}}>
      <div className='d-flex flex-column align-items-center pt-5 position-relative zIndex-3'>
        <Shield size="48" color="#32b1bb"/>
        <div className='w_50 d-flex flex-column gap-2 align-items-center justify-column-center'>
          <h1>Enhance Security, Maximize Performance</h1>
          <p style={{color: "#F1FFDA9E"}}>At Dataprime-Solutions, we deliver tailored security solutions to protect your data and assets, ensuring fast response times, high incident resolution, and reliable system performance for complete digital security.</p>
          <Button txt_color="#8CF1A9" border_color="#A4FF9661">Learn More</Button>
        </div>
      </div>
    </div>
  )
}

export default Security