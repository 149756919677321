import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaGlobe, FaBuilding, FaRocket, FaMapMarkerAlt } from "react-icons/fa"; // Importing icons
import "./Locations.css"; // Custom CSS

const locations = [
  { name: "MENA Region", description: "Serving the Middle East & North Africa with top-tier solutions.", icon: <FaGlobe /> },
  { name: "Singapore", description: "A hub for innovation and cutting-edge technology.", icon: <FaBuilding /> },
  { name: "United States", description: "Expanding digital frontiers across North America.", icon: <FaRocket /> },
  { name: "Cyprus", description: "Strategically positioned for European and global operations.", icon: <FaMapMarkerAlt /> },
];

const Locations = () => {
  return (
    <Container fluid className="bg-light p-5 text-center">
      <h2 className="mb-4">Our Locations</h2>
      <Row className="justify-content-center">
        {locations.map((location, index) => (
          <Col key={index} xs={12} md={6} lg={3} className="mb-4">
            <div className="location-box">
              <div className="location-icon">{location.icon}</div>
              <h4>{location.name}</h4>
              <p>{location.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Locations;
