export const letterAnimation = {
    hidden: { y: -50, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.1, // Stagger effect per letter
        type: "spring",
        stiffness: 500,
        damping: 25,
      },
    }),
  };

export const fadeUpAnimation = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
        duration: 0.6, // Animation duration
        ease: "easeOut",
        delay: 0.5, // Start after letters animate
        },
    },
};