import React from 'react'
import MapBg from '../../../assets/img/map_bg.jpg'
import './Companies.css'

const Companies = () => {
  return (
    <div className='companies__section position-relative d-flex align-items-center justify-content-center'>
        <div className="bg-white position-relative" style={{height: '30rem', width: '90%', top: '-5rem'}}>
            <div className='bg__map' style={{backgroundImage: `url(${MapBg})`}}>
                <div className="overlay"></div>
                <div className="row text_Content">
                    <div className="col-md-6 text-start">
                        <h1>Improve Your Experience</h1>
                    </div>
                    <div className="col-md-6 text-center text-lg-end">
                        <p>At Dataprime-Solutions, we believe in staying ahead of the curve when it comes to web design and security. Our commitment to innovation, customer satisfaction, and top-tier security solutions drives everything we do. Check out our latest updates and announcements below to learn more about how we're shaping the future of secure digital experiences.</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Companies