import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Banner } from '../Components/General/General'
import AboutBanner from '../assets/img/projects/project2.jpg'
import Content from '../Components/AboutUs/Content/Content'
import AboutMission from '../assets/img/About/aboutMission.jpg'
import Timeline from '../Components/AboutUs/Timeline/Timeline'
import Career from '../Components/AboutUs/Career/Career'

const About = () => {
  const location = useLocation();
  const timelineRef = useRef(null);
  const careerRef = useRef(null);

  useEffect(() => {
    // Check if we need to scroll to a specific section
    const urlParams = new URLSearchParams(location.search);
    const section = urlParams.get('section');
    
    if (section === 'CoreValues' && timelineRef.current) {
      // Use setTimeout to ensure the DOM is fully loaded
      setTimeout(() => {
        // First scroll to the element
        timelineRef.current.scrollIntoView({ behavior: 'smooth' });
        
        // Then adjust the scroll position by adding 30px
        setTimeout(() => {
          window.scrollBy({
            top: 180,
            behavior: 'smooth'
          });
        }, 500); // Wait for the initial scroll to complete
      }, 100);
    } else if (section === 'JoinUs' && careerRef.current) {
      // Use setTimeout to ensure the DOM is fully loaded
      setTimeout(() => {
        careerRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [location]);

  return (
    <>
      <Banner bgImg={AboutBanner} title="About Us" desc='Delivering Excellence, Driving Innovation' render='false'/>
      <div className="about__container">
        <div className="row">
          <div className="col-md-6">
            <div
              className='rounded d-none d-md-block'
              style={{
                backgroundImage: `url(${AboutMission})`,
                height: "500px",
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <Content />
          </div>
        </div>
      </div>
      <Timeline ref={timelineRef} />
      <Career ref={careerRef} />
    </>
  )
}

export default About