const NavLinks = [
  {menu: 'Home', link: '/'},
  {menu: 'What We Do', link: '/What-We-Do',
    submenu: [
      // { menu: 'Consulting', link: '/What-We-Do' },
      { menu: 'Technical Maintenance', link: '/What-We-Do?section=Technical' },
      { menu: 'Solutions', link: '/What-We-Do?section=Solutions' },
      { menu: 'Internships', link: '/What-We-Do?section=Internships' },
    ]
    // submenu: [
      //   { menu: 'Argus-X', link: '/Argus-x' },
      // ]
    },
    {menu: 'Who We Serve', link: '/Who-We-Serve'},
    // {menu: 'Portfolio', link: '/Portfolio',},
    { 
      menu: 'About Us', 
      link: '/About',
      submenu: [
        { menu: 'Who We Are', link: '/About' },
        { menu: 'Vision & Mission', link: '/About' },
        { menu: 'Core Values', link: '/About?section=CoreValues' },
        { menu: 'Join Us', link: '/About?section=JoinUs' },
      ]
    },
  {menu: 'Contact', link: '/Contact',},
]


export default NavLinks