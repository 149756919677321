import React, { useRef, forwardRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion, useInView } from "framer-motion";
import { letterAnimation } from '../../General/Animation';
import TimelineBg from '../../../assets/img/About/timeline.jpg';
import Timeline1 from '../../../assets/img/About/timeline1.jpg';
import Timeline2 from '../../../assets/img/About/timeline2.jpg';
import Timeline3 from '../../../assets/img/About/timeline3.jpg';
import Timeline4 from '../../../assets/img/About/timeline4.jpg';
import Timeline5 from '../../../assets/img/About/timeline5.jpg';
import './Timeline.css';

const Timeline = forwardRef((props, timelineRef) => {
  const animationRef = useRef(null);
  const isInView = useInView(animationRef, { once: true });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const carouselItems = [
    {id: 1, image: Timeline1, title: "Innovation", desc: "We continuously push boundaries to create cutting-edge solutions that meet the evolving needs of our clients."},
    {id: 2, image: Timeline2, title: "Excellence", desc: "We strive for the highest standards in every aspect of our work, delivering reliable and impactful results." },
    {id: 3, image: Timeline3, title: "Integrity", desc: "We operate with transparency, honesty, and accountability, building trust with our clients and partners." },
    {id: 4, image: Timeline4, title: "Collaboration", desc: "We believe in the power of teamwork, working closely with clients, colleagues, and stakeholders to achieve shared success." },
    {id: 5, image: Timeline5, title: "Customer-Centricity", desc: "Our clients are at the heart of everything we do. We focus on understanding their unique challenges and delivering solutions that exceed expectations." },
    {id: 6, image: Timeline2, title: "Adaptability", desc: "In a rapidly changing world, we embrace flexibility and agility, ensuring our solutions remain relevant and effective." },
    {id: 7, image: Timeline3, title: "Impact", desc: "We are committed to making a positive difference in industries, communities, and the lives of those we serve." },
    {id: 8, image: Timeline4, title: "Lifelong Learning", desc: "We foster a culture of continuous improvement and knowledge-sharing to stay at the forefront of technology and innovation." }
  ];

  return (
    <div className="TimelineBg__section" ref={timelineRef}>
      <div 
        className="TimelineBg__video" 
        style={{ 
          backgroundImage: `url(${TimelineBg})`, 
          width: '100%', 
          height: '140vh', 
          backgroundSize: 'cover', 
          backgroundPosition: 'center center' 
        }}
      >
        <div className='position-absolute' style={{width: '100%', height: '140vh', background: 'rgba(0,0,0,0.5)'}}></div>
        <div className="TimelineBg__content">
          <h1 
            className="text-white display-1 fw-bold font-grotesque d-flex" 
            style={{ letterSpacing: '0.01rem' }}
            ref={animationRef}
          >
            {'Values'.split("").map((letter, index) => (
              <motion.span
                key={index}
                custom={index}
                variants={letterAnimation}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                className="letter-animation"
                style={{
                  display: "inline-block",
                }}
              >
                {letter === " " ? "\u00A0" : letter}
              </motion.span>
            ))}
          </h1>
          
          <div className="slick-timeline-container">
            <Slider {...settings}>
              {carouselItems.map((item, index) => (
                <div key={item.id} className="slick-slide">
                  <div className="d-flex justify-content-center flex-column align-items-center gap-2" >
                    <div style={{height: '30vh', overflow: 'hidden'}} className={`${index%2 === 0 ? 'order-1' : 'order-3'} d-flex align-items-center justify-content-center`}>
                      <img src={item.image} className='w-100 p-3' style={{objectFit: 'cover'}} alt="" />
                    </div>
                    <div className='order-2 px-4 position-relative d-flex align-items-center justify-content-center'  style={{height: '0.1rem', background: 'rgba(255,255,255,0.5)', width: '100%'}}>
                      <div className='position-absolute' style={{width: '25px', height: '25px', background: 'rgba(255,255,255,0.5)', borderRadius: '50%'}}></div>
                    </div>
                    <div style={{height: '30vh', background: 'radial-gradient(circle, rgba(255,255,255,0.2) 0%, transparent 50%)'}} className={`${index % 2 === 0 ? 'order-3 align-items-center' : 'order-1 align-items-center'} d-flex flex-column text-content align-items-start justify-content-center`}>
                      <h3 className='text-center fw-bold'>{item.title}</h3>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Timeline;
