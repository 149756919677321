import React from 'react';
import WhatWeDoSec from '../Components/WhatWeDo/WhatWeDo';
import { Banner } from '../Components/General/General';
import ContactBg from '../assets/img/contactBg.jpg';

const WhatWeDo = ({ section }) => {
  
  let items, sectionTitle, sectionDesc, parag;

  switch(section)  {
    case 'Technical':
      sectionTitle = 'Ensuring Reliability, Security, and Peak Performance';
      sectionDesc= (
        <>
          At <span className='fw-bold'>Data Prime</span>, we understand that seamless operations and <span className='fw-bold'>robust system security</span> are critical for businesses in the digital age. Our <span className='fw-bold'>comprehensive technical maintenance services</span> are designed to maximize uptime, optimize performance, and <span className='fw-bold'>safeguard</span> critical data infrastructure.
        </>
      );
      parag="With Data Prime's technical expertise, businesses can operate with confidence, knowing their systems are secure, reliable, and always at peak performance.";
      items = [
        { title: "Proactive Monitoring & Support ", content: "We deploy real-time monitoring and predictive maintenance strategies to identify and resolve potential issues before they impact operations."},
        { title: "Regular System Audits & Optimization", content: "Our team conducts thorough system evaluations to improve efficiency, mitigate vulnerabilities, and enhance overall performance." },
        { title: "Patch Management & Security Updates", content: "We ensure systems remain up-to-date with the latest security patches, minimizing risks associated with cyber threats."},
        { title: "Disaster Recovery & Business Continuity Planning", content: "We develop robust contingency strategies to prevent data loss and maintain business continuity in the face of unexpected disruptions."},
      ];
    break;

    case 'Solutions':
      sectionTitle = 'Driving Business Transformation with Cutting-Edge Technology';
      sectionDesc= (
        <>
          At <span className='fw-bold'>Data Prime</span>, we specialize in delivering <span className='fw-bold'>tailored, high-impact technology solutions</span> that empower businesses to unlock the full potential of <span className='fw-bold'>geospatial intelligence, AI, and advanced analytics.</span> Whether it's optimizing operations, enhancing decision-making, or <span className='fw-bold'>gaining deeper insights into complex data,</span>our solutions are designed to deliver <span className="fw-bold">tangible business outcomes.</span>
        </>
      );
      parag="By integrating the latest in geospatial intelligence, machine learning, and cloud computing, we help organizations harness data-driven innovation and transform the way they operate.";
      items = [
        { title: "Geospatial Analytics & Location Intelligence", content: "We leverage GIS technologies, satellite imagery, and spatial data modeling to provide insights into consumer behavior, risk analysis, and market trends."},
        { title: "AI-Powered Predictive Modeling", content: "Our AI-driven models analyze historical and real-time data to predict trends, risks, and opportunities, helping businesses stay ahead of the curve." },
        { title: "Custom Software Development", content: " We build scalable, user-centric applications tailored to specific business needs, from data processing platforms to decision-support systems."},
        { title: "Cloud Integration & Data Infrastructure", content: "We design and implement cloud-based solutions that enhance data accessibility, security, and performance across organizations."},
        { title: "Automated Workflow & Process Optimization", content: "Our intelligent automation solutions streamline operations, reducing manual workloads and enhancing overall efficiency."},
      ];
    break;
      
    case 'Internships':
      sectionTitle = 'Shaping the Next Generation of Tech Innovators';
      sectionDesc= (
        <>
          At <span className='fw-bold'>Data Prime</span>, we are committed to <span className='fw-bold'>nurturing future leaders</span>  in the fields of <span className='fw-bold'>technology, geospatial intelligence, and data science.</span> Our internship programs offer a unique <span className='fw-bold'>learning experience,</span> providing young professionals with the opportunity to work on <span className="fw-bold"> real-world projects</span> while being mentored by <span className="fw-bold"> industry experts.</span>
        </>
      );
      parag="Our internship program isn’t just about learning—it’s about applying knowledge, building expertise, and preparing for a future in cutting-edge technology.";
      items = [
        { title: "Hands-on Experience with Emerging Technologies", content: "Interns work directly with geospatial tools, AI-driven analytics, and cloud platforms to solve industry-specific challenges."},
        { title: "Mentorship from Experts", content: "Our team of experienced professionals guides interns through practical problem-solving, project management, and strategic thinking." },
        { title: "Exposure to Real-World Business Challenges", content: "Interns actively contribute to live projects, gaining insights into how technology drives business success and innovation."},
        { title: "Career Development & Networking Opportunities", content: "We offer career guidance, networking sessions, and pathways for potential full-time employment with Data Prime."},
      ];
    break;
    default: 
    break;
  } 

  return (
    <div>
      <Banner bgImg={ContactBg} title='' desc='' showOverlay='false' />
      <WhatWeDoSec img={ContactBg} sectionItems={items} Title={sectionTitle} Desc={sectionDesc} Parag={parag}/>
    </div>
  );
};

export default WhatWeDo;
