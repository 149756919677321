import React, { forwardRef } from 'react';
import './Career.css'; // You'll need to create this CSS file
import { FaArrowDown } from 'react-icons/fa';
import { motion } from "framer-motion";

const Career = forwardRef((props, ref) => {
  const handleRedirect = () => {
    window.open('https://form.jotform.com/250206127605447', '_blank');
  };

  return (
    <div className="career-container flex-column d-flex flex-md-row align-items-center justify-content-center" ref={ref}>
      <div className='text-center text-md-start'>
        <h1 style={{ fontSize: '80px' }} className='font-grotesque'>Let's work together.</h1>
        <p>Join our innovative team at DataPrime Solution, where we empower talent to shape the future of data-driven solutions and unlock limitless career potential.</p>
      </div>
      <div className='d-flex flex-column align-items-center justify-content-center gap-5'>
        {/* Add the bouncing animation to the arrow */}
        <motion.div
          animate={{ y: [0, 20, 0] }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <FaArrowDown size="60" fill='#32b1bb' />
        </motion.div>
        <button onClick={handleRedirect} className="redirect-btn">
          Apply Now
        </button>
      </div>
    </div>
  );
});

export default Career;
