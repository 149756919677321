import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Banner } from '../Components/General/General'
import ArgusX from '../assets/img/argusX.webp'

const Argus = () => {
  return (
        <Banner bgImg={ArgusX} title='Argus-X' desc='Transforming Geospatial Data into Real-Time Intelligence from Space to Earth.' btnText="Contact Sales" render='true'/>
    );
};

export default Argus;
