import React from 'react'
import { Banner } from '../Components/General/General'
import ContactBg from '../assets/img/contactBg.jpg'
import ContactForm from '../Components/Contact/ContactForm'

const Contact = () => {
  return (
    <>
        <Banner bgImg={ContactBg} title='' desc='' showOverlay = 'false' />
        <ContactForm />
    </>
  )
}

export default Contact