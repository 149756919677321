import React from 'react';
import './Flow.css';
import ServiceBg from '../../../assets/img/section_bg.jpg';
import { Button, CardFlow } from '../../General/General';
import { Shield, Shield1, Shield2, Shield3 } from '../../icons/Icons';

const Services = () => {
  return (
    <div className='flow_section position-relative' style={{backgroundImage: `url(${ServiceBg})` }}>
      <div className='d-flex flex-column align-items-center pt-5 position-relative zIndex-3'>
        <Shield size="48" color="#7ee3f5" />
        <div className='w_50 d-flex mt-4 flex-column gap-2 align-items-center justify-column-center'>
          <h1>Transforming Location Intelligence</h1>
          <p style={{ color: "#F1FFDA9E" }}>
            At DataPrime Solutions, we leverage the power of Argus-X to deliver advanced location intelligence solutions. Our structured and innovative approach ensures your business thrives in a data-driven world, optimizing decision-making with actionable insights.
          </p>
          <Button txt_color="#7ee3f5" border_color="#7ee3f5">Learn More</Button>
        </div>
        <div className='px-5 d-flex flex-column flex-lg-row justify-content-center flowCardSection'>
          <CardFlow 
            position="0" 
            icon={<Shield size={40} color='#15251C' />} 
            title='Discovery & Assessment' 
            desc='Understanding your needs is the first step. Argus-X begins with a comprehensive analysis of your business goals, data sources, and geospatial requirements to design tailored solutions.'
          />
          <CardFlow 
            position="6rem" 
            icon={<Shield1 size={40} color='#15251C' />} 
            title='Solution Design & Integration' 
            desc='Argus-X solutions are customized to meet your specific challenges. We integrate advanced location intelligence tools seamlessly into your existing infrastructure.' 
          />
          <CardFlow 
            position="6rem" 
            icon={<Shield2 size={40} color='#15251C' />} 
            title='Monitoring & Optimization' 
            desc='Continuous monitoring and optimization of your systems ensure sustained efficiency and adaptability as your needs evolve.' 
          />
          <CardFlow 
            position="0" 
            icon={<Shield3 size={40} color='#15251C' />} 
            title='Growth & Scalability' 
            desc='As your organization grows, Argus-X scales with you, enhancing capabilities with predictive analytics, real-time insights, and advanced AI-driven solutions.' 
          />
        </div>
      </div>
    </div>
  );
};

export default Services;