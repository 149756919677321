import React, { useState } from 'react'
import { AccordionTech} from '../General/General'
import AboutMission from '../../assets/img/About/aboutMission.jpg'
import { Fade } from 'react-awesome-reveal'
import Mv1 from '../../assets/img/mv1.jpg';
import './WhatWeDo.css'

const WhatWeDo = ({sectionItems, Title, Desc, Parag}) => {
  const [activeIndex, setActiveIndex] = useState(0);


  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div className='mt-5'>
        <div className='w-100 d-flex align-items-center justify-content-center mb-5'>
          <div className='w-75 d-flex flex-column gap-2 align-items-center justify-column-center'>
            <Fade cascade direction='up'>
              <h1 className='display-6 fw-bold'>{Title}</h1>
              <p className='text-center'>{Desc}</p>
            </Fade>
          </div>
        </div>
        <div className='container'>
          <div className="row">
            <div className="col-md-6">
              <div
                className='rounded-lg d-none d-md-block'
                style={{
                  borderRadius: '25px',
                  backgroundImage: `url(${AboutMission})`,
                  height: "500px",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              ></div>
            </div>
            <div className="col-md-6">
              <AccordionTech
                item={sectionItems}
                toggleAccordion={toggleAccordion}
                setActiveIndex={setActiveIndex}
                activeIndex={activeIndex}
              />
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div
            className='d-none d-md-flex align-items-center justify-content-center position-relative'
            style={{
              backgroundImage: `url(${Mv1})`,
              height: "500px",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundPosition: "center center",
            }}
          >
            <div className='position-absolute top-0 left-0 w-100' style={{
              height: '500px',
              background: 'rgba(0,0,0,0.6)',
            }}></div>
              <h1 className='text-white w_75 position-relative z-2'>{Parag}</h1>
            </div>
        </div>
      </div>
    </div>
  )
}

export default WhatWeDo