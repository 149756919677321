import React from 'react';
import './DataVid.css';
import DataVideo from '../../../assets/img/DataVid.mp4';
import { Button } from '../../General/General';

const DataVid = () => {
  return (
    <div className="DataVid__section position-relative top-0">
      {/* Video Background */}
      <video 
        autoPlay 
        muted 
        playsInline 
        loop 
        src={DataVideo} 
        className="DataVid__video" 
        style={{ width: '100%', objectFit: 'cover' }}
      />
      {/* Overlay Content */}
      <div className="DataVid__content position-absolute translate-middle text-center">
        <h1>Transforming Data into Prime Solutions.</h1>
       
        <Button txt_color="#fff" border_color="">Start Exploring Today</Button>
      </div>
    </div>
  );
};

export default DataVid;
