import React from 'react';
import logo from "../../assets/img/logoNoBg.png";
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const footerNav = [
    {
      title: 'What We Do',
      subs: [
        { title: 'Consulting', link: '/What-We-Do' },
        { title: 'Technical Maintenance', link: '/What-We-Do' },
        { title: 'Solutions', link: '/What-We-Do' },
        { title: 'Internships', link: '/What-We-Do' },
      ]
    },
    {
      title: 'About Us',
      subs: [
        { title: 'Who We Are', link: '/About' },
        { title: 'Vision & Mission', link: '/About' },
        { title: 'Core Values', link: '/About' },
        { title: 'Join Us', link: '/About' },
      ]
    },
  ];

  return (
    <footer className="footer bg-dark text-white p-4">
      <div className="d-flex flex-column flex-lg-row align-items-lg-start align-items-center">
        {/* Logo and Copyright Section */}
        <div className="px-4 py-3 text-start d-flex flex-column align-items-lg-start align-items-center justify-content-start w_25 order-lg-1 order-4 mt-5 mt-lg-0">
          <div className="mb-3 d-flex align-items-center gap-3 flex-lg-row flex-column">
            <img src={logo} width="90" height="50" alt="Logo" />
            <h5 className="mt-2 w_50">DataPrime Solutions</h5>
          </div>
          <div className='text-center text-lg-start'>
            <p className="mb-0" style={{ fontSize: '14px' }}>&copy; 2025 DataPrime Solutions.</p>
            <p className="mb-0 text-secondary" style={{ fontSize: '12px' }}>All Rights Reserved.</p>
          </div>
        </div>

        {/* Footer Navigation Links */}
        <div className="d-flex w_50 order-2 flex-row justify-content-center flex-lg-row">
          {footerNav.map((footerLink, i) => (
            <div key={i} className="w_50 px-3 d-flex flex-column align-items-start align-items-lg-start gap-3">
              <h5 className="mb-2">{footerLink.title}</h5>
              <ul className="list-unstyled">
                {footerLink.subs.map((subLink, j) => (
                  <li key={j} className='text-start text-lg-start pb-2'>
                    <Link to={subLink.link} className="text-decoration-none">
                      {subLink.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Subscribe Section */}
        <div className="w_25 px-4 d-flex flex-column align-items-center align-items-lg-start order-3 mt-5 mt-lg-0">
          <h5 className="mb-3">Subscribe to our newsletter</h5>
          <p className="text-secondary" style={{ fontSize: '14px' }}>Stay updated with our latest news and offers.</p>
          <div className="d-flex w-100 flex-lg-row flex-column gap-3 gap-lg-0">
            <input 
              type="email" 
              className="form-control me-2" 
              placeholder="Enter your email" 
              style={{ flex: 1 }} 
            />
            <button className="btn btn-primary">Subscribe</button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
