import React from 'react'
import Industries from '../Components/WhoWeServe/Industry'
import { Banner } from '../Components/General/General'
import ContactBg from '../assets/img/contactBg.jpg';
import { Fade } from 'react-awesome-reveal';


const WhoWeServe = () => {
  return (
    <>
        <Banner bgImg={ContactBg} title='' desc='' showOverlay='false' />
        <div className='w-100 d-flex flex-column align-items-center justify-content-center py-5' style={{background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)'}}>
            <div className='w-75 d-flex flex-column gap-2 align-items-center justify-column-center pb-5'>
            <Fade cascade direction='up'>
                <h1 className='display-6 fw-bold'>Empowering Industries with Advanced Location Intelligence & Data-Driven Solutions</h1>
                <p className='text-center'> At <span className='fw-bold'>Data Prime</span>,  we specialize in transforming <span className='fw-bold'>complex data into actionable insights</span>, empowering industries to make <span className='fw-bold'>smarter decisions, optimize operations, and drive sustainable growth.</span> Our tailored solutions <span className='fw-bold'> leverage geospatial intelligence, AI-powered analytics, and predictive modeling </span>to help businesses and organizations <span className="fw-bold">stay ahead of industry challenges.</span></p>
            </Fade>
            </div>
            <div><Industries /></div>
        </div>
    </>
  )
}

export default WhoWeServe